import styles from "./paywall.module.css";
import Logo from "../../assets/logo.svg";
import { useEffect, useState } from "react";
import CloseIcon from "../../assets/close-icon.svg";
import * as amplitude from "@amplitude/analytics-browser";
import { useGetQueryParams } from "../../hooks/useGetQueryParams";
import { useReadLocalStorage } from "usehooks-ts";
import { getPaymentData, productIds } from "../../data/paymentData";
import { getTempAuthToken } from "../../utils/getTempAuthToken";
import { Circle } from "../common-parts/circle";
import { fbg } from "../../utils/fbg";
import { ToggleSwitch } from "../common-parts/toggle-switch";

export const Paywall = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [index, setIndex] = useState(1);
  const trialDays =
    searchParams.get("trial_days") && Number(searchParams.get("trial_days")) < 7
      ? Number(searchParams.get("trial_days"))
      : 7;
  const [isTrial, setIsTrial] = useState(true);
  const [isLoad, setIsLoad] = useState(false);
  const queryParams = useGetQueryParams();
  const onboardingData =
    useReadLocalStorage<{ [key in string]: string[] }>("onboarding");
  const paymentData = getPaymentData();
  const [productId, setProductId] = useState(paymentData[1]);
  const isWeb = searchParams.get("dest") === "web";
  const closeUrl = isWeb
    ? "https://web.nufa.ai"
    : "https://nufa.onelink.me/OSCY/rvrapu8n";
  const source =
    searchParams.get("canceled") === "true" ? "purchase_failed" : "onboarding";

  useEffect(() => {
    if (!onboardingData?.["email"][0]) {
      window.location.href = "/";
      return;
    }

    setTimeout(() => {
      setShowCloseButton(true);
    }, 3000);

    amplitude.track("show_paywall_screen", {
      paywall: "base",
      source,
    });

    if (source === "purchase_failed") {
      amplitude.track("purchase_cancel");
    }

    getTempAuthToken();
  }, []);

  const track = (index: number) => {
    setProductId(paymentData[index]);
    setIndex(index);

    amplitude.track("paywall_product_tap", {
      id: productIds[index].productId,
    });
  };

  const handlerContinue = async () => {
    setIsLoad(true);

    amplitude.track("paywall_purchase_tap", {
      id: productIds[index].productId,
    });

    const myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    if (!process.env.REACT_APP_IS_PROD) {
      myHeaders.append("is-sandbox", "true");
    }

    const metadata: { [key in string]: string | number | boolean } = {
      amplitude: amplitude.getDeviceId() || "",
    };

    if (isWeb) {
      metadata["is_web"] = true;
    }

    const tempAuthToken = await getTempAuthToken();

    if (!tempAuthToken) {
      setIsLoad(false);
      return;
    }

    myHeaders.append("api-auth-key", tempAuthToken);

    const body: any = {
      price_id: productId,
      email: onboardingData?.["email"][0],
      subscription_details: {
        metadata,
      },
      source: "onboarding",
      query: window.location.search
        ? `${window.location.search}&product_id=${productIds[index].productId}`
        : `?product_id=${productIds[index].productId}`,
      success_url: `${window.location.origin}/success?product_id=${
        productIds[index].productId
      }&${isTrial ? "is_trial=true" : ""}`,
    };

    if (isTrial) {
      body["trial_days"] = 3;
    }

    fetch(`${process.env.REACT_APP_API_URL || ""}/api/v1/stripe/session`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow",
    })
      .then((response) => {
        return response.json();
      })
      .then((data: { [key in string]: string }) => {
        if (data["url"]) {
          amplitude.track("show_checkout_screen");

          fbg("track", "InitiateCheckout");

          document.location.href = data["url"];
        }
      })
      .catch((error) => {
        amplitude.track("purchase_failure", {
          error: error,
        });
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  return (
    <div className={styles.videoBg}>
      <video playsInline autoPlay loop muted>
        <source
          src="https://dev-cdn.nufa.ai/web/paywall.mp4"
          type="video/mp4"
        />
      </video>
      <div className={styles.videoBgEffect}></div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <img src={Logo} alt="Nufa" />
            <span className={styles.logoLabel}>PRO</span>
          </div>
          <div>
            <h3>Get full access</h3>
            <ul>
              <li>New Filters Every Day</li>
              <li>Instant processing</li>
              <li>Hight Resolution</li>
              <li>No Limits</li>
            </ul>
          </div>
          <div className={styles.plans}>
            {productIds.map((item, index) => (
              <div className={styles.plan} key={item.productId}>
                <input
                  type="radio"
                  name="plan"
                  id={item.productId}
                  hidden
                  defaultChecked
                  onClick={() => track(index)}
                />
                <label htmlFor={item.productId}>
                  <span className={styles.costPeriod}>
                    {item.title} ${item.price}
                  </span>
                  <span className={styles.costDay}>
                    ${item.dayPrice} per day
                  </span>
                </label>
                {!!item.discount && (
                  <div className={styles.discount}>{item.discount}% OFF</div>
                )}
              </div>
            ))}
            <ToggleSwitch
              id="trial-days"
              defaultChecked={isTrial}
              onChange={(value) => setIsTrial(value)}
            >
              {trialDays} day Free Trial
            </ToggleSwitch>
          </div>

          <button
            className={styles.button}
            onClick={handlerContinue}
            disabled={isLoad}
          >
            {isLoad ? <Circle /> : "Continue"}
          </button>
        </div>
      </div>
      {showCloseButton && (
        <a href={`${closeUrl}${queryParams}`} className={styles.closeButton}>
          <img src={CloseIcon} alt="close" />
        </a>
      )}
    </div>
  );
};
