import Cookies from "js-cookie";
import * as amplitude from "@amplitude/analytics-browser";

const MAX_AUTH_RETRIES = 3;

export const getTempAuthToken = async () => {
  let tempAuthToken = Cookies.get("temp-auth-token") ?? "";
  
  const getNewToken = async (retryCount = 0) => {
    if (retryCount > MAX_AUTH_RETRIES) {
      console.error("Failed to refresh authentication token");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL || ""}/api/v1/web-auth`,
        {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            "api-auth-key": tempAuthToken,
          },
          body: JSON.stringify({
            "api-auth-key": tempAuthToken,
          }),
        }
      );

      if (!response.ok) {
        await getNewToken(retryCount += 1);
      }

      const data = await response.json();

      if (data && data["auth-token"]) {
        tempAuthToken = data["auth-token"] as string;
        Cookies.set("temp-auth-token", tempAuthToken);
      } else {
        await getNewToken(retryCount += 1);
        console.error("did not receive access token");
      }
    } catch (error) {
      await getNewToken(retryCount += 1);
    }
  };

  if (tempAuthToken) {
    await getNewToken();
    return tempAuthToken;
  }

  const setAuthTemp = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL || ""}/api/v1/temp-auth`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            temp_id: amplitude.getDeviceId()!,
          }),
        }
      );

      if (!response.ok) {
        await setAuthTemp();
      }

      const data = await response.json();

      if (data && data["auth-token"]) {
        tempAuthToken = data["auth-token"] as string;
        Cookies.set("temp-auth-token", tempAuthToken);
      } else {
        await setAuthTemp();
        console.error("did not receive access token");
      }
    } catch (error) {
      await setAuthTemp();
    }
  };

  await setAuthTemp();

  return tempAuthToken;
};
