import { useState } from 'react';
import style from './styles.module.css';

type Props = {
  id: string;
  defaultChecked?: boolean;
  onChange: (value: boolean) => void;
  children?: React.ReactNode;
};

export const ToggleSwitch = ({
  id,
  defaultChecked = false,
  onChange,
  children,
}: Props) => {
  const [isToggled, setIsToggled] = useState(defaultChecked);
  const onToggle = () => {
    setIsToggled(!isToggled);
    onChange(!isToggled);
  };

  return (
    <label htmlFor={id} className={style.wrapper}>
      {children}
      <div className={style.toggleSwitch}>
        <input id={id} type="checkbox" checked={isToggled} onChange={onToggle} />
        <span className={style.switch} />
      </div>
    </label>
  );
};
