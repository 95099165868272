import LogoIcon from "../../assets/xl-logo.svg";
import style from "./success-payment.module.css";
import { Footer } from "../common-parts/footer";
import { useGetQueryParams } from "../../hooks/useGetQueryParams";
import * as amplitude from "@amplitude/analytics-browser";
import { useEffect } from "react";
import { fbg } from "../../utils/fbg";
import { productIds } from "../../data/paymentData";

const url = process.env.REACT_APP_IS_PROD
  ? "https://cdn.nufa.ai/app-scheme/index.html"
  : "https://dev-cdn.nufa.ai/app-scheme/index.html";

export const SuccessPayment = () => {
  const queryParams = useGetQueryParams();
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    amplitude.track('show_success_screen', {
      query: window.location.search
    });

    const productId = searchParams.get('product_id');
    const product = productIds.find(item => item.productId === productId);

    amplitude.track("purchase_success", {
      paywall: "base",
      source: 'onboarding',
      id: productId,
    });

    if(searchParams.get('is_trial')) {
      fbg("track", "StartTrial", {
        currency: "USD",
        value: product?.price ?? '',
      });
    } else {
      fbg("track", "Purchase", {
        currency: "USD",
        value: product?.price ?? '',
      });
    }

    fbg("trackCustom", "purchase_success", {
      id: productId ?? '',
    });
  }, [])

  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <img src={LogoIcon} alt="Logo" />
          <h1>Just one more step!</h1>
          <div className={style.list}>
            <h2>It’s time to download the app:</h2>
            <ol>
              <li>Click the button below to download the app</li>
              <li>Open the app and check if your subscribtion is active</li>
            </ol>
            <a
              className={style.downloadButton}
              href={`https://nufa.onelink.me/OSCY/rvrapu8n${queryParams}`}
              onClick={() => {
                amplitude.track("success_screen_download_tap");
              }}
            >
              Download Nufa
            </a>
            <ol start={3}>
              <li>
                If you dnon’t see the active subscription, click the Activate
                Subscription button with the app installed
              </li>
            </ol>
            <a
              href={`${url}${queryParams}`}
              onClick={() => {
                amplitude.track("success_screen_activate_tap");
              }}
            >
              Activate Subscription
            </a>
          </div>
          <div className={style.support}>
            <h2>Having trouble logging in?</h2>
            Contact our friendly support team
            <a
              href="mailto:support@mimesislabs.ai"
              onClick={() => {
                amplitude.track("success_screen_contact_us_tap");
              }}
            >
              support@mimesislabs.ai
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
